<template>
<div class="explore-container" :class="'route-'+$route.name">

    <!--SHOWN HERE FOR MOBILE OR FOR AUTHENTICATION IN CASE OF DESKTOP VERSION, IN DESKTOP VERSION ALL THE OTHER STUFF WILL BE RENDERED IN SIDEBAR-->
    <router-view v-slot="{ Component, Route }" name="desktopCenter" v-if="!$parent.isMobile" :isMobile="$parent.isMobile">
        <transition name="slide-up" mode="out-in">
            <component :is="Component" :key="Route.path" />
        </transition>
    </router-view>
    
    <template v-if="$parent.isMobile">
        <transition name="fade">
            <router-view name="mobile" :user="user" :innerHeight="innerHeight" :isMobile="$parent.isMobile" :userLoaded="userLoaded">
            </router-view>
        </transition>
    </template>

    <!--Mostrata se è da desktop e come parametro della rotta, la mappa è abilitata, oppure se è da telefono solo se è su Explore-->
    <OpenStreetMap v-if="user != null && ((!$parent.isMobile && $route.meta.map) || ($parent.isMobile && $route.name == 'Esplora'))" :poi-click="handlePoiClick" style="width: 100%; position: absolute; top: 0; left: 0;" @boundsChanged="boundsChanged" :pois="pois" :center="mapCoords" :userCoords="userCoords" :innerHeight="innerHeight" @userCenteredChanged="(e) => {shouldBeCenteredOnUpdate = e}" />
    
    <PoiSearch v-if="user != null && (!$parent.isMobile || ($parent.isMobile && ($route.name == 'Esplora' || $route.path.startsWith('/explore/poi'))))" v-show="((!$parent.isMobile && $route.meta.map) || ($parent.isMobile && $route.name == 'Esplora'))" :is-mobile="$parent.isMobile" :userCoords="userCoords" @search="applyFilters" :user="user" :userLoaded="userLoaded"/>
    <DialogWrapper v-if="dialogComponent != null" :component="dialogComponent" :callback="dialogCallback" :is-mobile="$parent.isMobile" :data="dialogData" :prevent-close="dialogPreventClose"/>
    <MessageOverlay v-if="addToTripMode" @dismiss="closeAddToTripMode" :message="$t('explore.addToTripMode')" />
    <MessageOverlay v-if="tripPoisMode" @dismiss="() => {tripPoisMode = false}" :message="$t('explore.tripPoisMode')" />
    <slideout-panel v-if="!$parent.isMobile"></slideout-panel>

</div>
</template>

<script>
import {
    apiCall
} from '../utils/ApiMiddleware';
import POI from '../components/elements/POI.vue';
import OpenStreetMap from '../components/OpenStreetMap.vue';
import BreadCumbWrapper from '../components/BreadCumbWrapper.vue';
import BreadCumb from '../components/BreadCumb.vue';
import Trips from '../components/elements/Trips.vue';
import Trip from '../components/elements/Trip.vue';
import NewReview from '../components/elements/NewReview.vue';
import Reviews from '../components/elements/Reviews.vue';
import Profile from '../components/elements/Profile.vue';
import Favourites from '../components/elements/Favourites.vue';
import PoiSearch from '../components/PoiSearch.vue';
import DialogWrapper from '../components/DialogWrapper.vue';
import {
    GlobalEventEmitter
} from '../utils/GlobalEventEmitter';
import MessageOverlay from '../components/MessageOverlay.vue';
import TripDateDialog from '../components/dialogs/TripDateDialog.vue';
import EditProfile from '../components/elements/EditProfile.vue';
import Subscription from '../components/elements/Subscription.vue';
import Invoicing from '../components/elements/Invoicing.vue';
import SubscriptionCancelled from '../components/elements/SubscriptionCancelled.vue';
import SubscriptionConfirmed from '../components/elements/SubscriptionConfirmed.vue';
import PrivacyPolicy from '../components/elements/PrivacyPolicy.vue';
import TOS from '../components/elements/TOS.vue';
import Notifications from '../components/elements/Notifications.vue';
import TrialExpiredDialog from '../components/dialogs/TrialExpiredDialog.vue';
import RenewFailedDialog from '../components/dialogs/RenewFailedDialog.vue';
import DeactivatedDialog from '../components/dialogs/DeactivatedDialog.vue';
import TrialDialog from '../components/dialogs/TrialDialog.vue';
import UpdateAppDialog from '../components/dialogs/UpdateAppDialog.vue';
import OfflineDialog from '../components/dialogs/OfflineDialog.vue';
import { Geolocation } from '@capacitor/geolocation';
import { compare } from 'compare-versions';
import { Device } from '@capacitor/device';

export default {
    name: 'App',
    components: {
        OpenStreetMap,
        BreadCumb,
        PoiSearch,
        DialogWrapper,
        MessageOverlay
    },

    props: {
        addToTripMode: { // if this is enabled, when the user will click on a poi, it will be added to the trip, otherwise the POI Details will be shown (used in handlePoiClick)
            type: Boolean,
            default: false
        },

        trip: Object,

        user: {
            type: Object,
            default: function () {
                return null;
            }
        },

        userLoaded: {
            type: Boolean,
            default: false
        },

        innerHeight: {
            type: Number,
            default: 100,
            required: false
        },

        tripPois: {
            type: Array,
            default: function () {
                return null;
            }
        }
    },

    computed: {
        enabledFeatures() {
            return this.$FEATURES.settings;
        },
    },

    data() {
        return {
            pages: {
                // Per poterli mostrare nella sidebar è necessario associare il nome della rotta (proprietà Name), al componente da mostrare nel BreadCumbWrapper che a sua volta è mostrato nella sidebar, altrimenti non viene visualizzato da desktop
                'Scheda del luogo': POI,
                'Viaggi': Trips,
                'Viaggio': Trip,
                'Preferiti': Favourites,
                'Recensione': NewReview,
                'Recensioni': Reviews,
                'Profilo': Profile,
                'Modifica profilo': EditProfile,
                'Sottoscrizione': Subscription,
                'Fatturazione': Invoicing,
                'Cancellazione': SubscriptionCancelled,
                'Attivata': SubscriptionConfirmed,
                'Notifiche': Notifications,
                'TOS': TOS,
                'Privacy Policy': PrivacyPolicy
            },
            panel: null,

            dialogComponent: null,
            dialogCallback: null,
            dialogData: null,
            dialogPreventClose: null,

            pois: [],
            tripPoisMode: false,

            mapCoords: [45.4627042, 9.0953322],
            userCoords: null,
            mapCentered: false,

            selectedPoi: null,

            filters: {},
            nearestPoi: null,
            bounds: {},

            geoLocationWatch: null,

            shouldBeCenteredOnUpdate: false,

        }
    },
    created() {
        // adds the event listener function that will handle the event
        GlobalEventEmitter.$on('showDialog', this.showDialog)
        GlobalEventEmitter.$on('hideDialog', this.hideDialog)
        GlobalEventEmitter.$on('goToCoords', this.goToCoords)
        GlobalEventEmitter.$on('askForGPS', this.askForGPS)
        GlobalEventEmitter.$on('showPaymentRequired', this.showPaymentRequired)

        // listen for online - offline events
        window.addEventListener('offline', this.checkOffline);
        window.addEventListener('online', this.checkOffline);

    },
    beforeDestroy() {
        // removes event listener
        GlobalEventEmitter.$off('showDialog', this.showDialog)
        GlobalEventEmitter.$off('hideDialog', this.hideDialog)
        GlobalEventEmitter.$off('goToCoords', this.goToCoords)
        GlobalEventEmitter.$off('askForGPS', this.askForGPS)
        GlobalEventEmitter.$off('showPaymentRequired', this.showPaymentRequired)

        window.removeEventListener('offline', this.checkOffline);
        window.removeEventListener('online', this.checkOffline);

        if (this.geolocationWatch != null) {
            Geolocation.clearWatch({
                id: this.geolocationWatch
            });
        }

    },

    async mounted() {
        this.checkUpdate();
        this.checkOffline();
        if (this.$route.name != 'Esplora') { // l'utente sta provando a visitare direttamente una sotto-rotta (eg. visitando viaggi da contact-us)
            // wait for page completely loaded
            window.onload = () => {
                this.showRoute(this.$route)
            }
            this.showRoute(this.$route)
        }
        if (this.tripPois != null && this.tripPois.length > 0) {
            this.tripPoisMode = true;
            this.pois = this.tripPois;
            GlobalEventEmitter.$emit('setZoom', 15)
            GlobalEventEmitter.$emit('goToCoords', [this.pois[0].latitude, this.pois[0].longitude])
        }
        if (this.$route.meta.map) {
            this.centerMap();
        }

        const status = this.$route.query.status;
        if(status == 'login_succeeded'){
            this.$vs.notification({
                title: this.$t('login.title'),
                text: this.$t('login.messages.success'),
                color: 'success',
                position: 'top-right'
            });
            this.$router.replace({
                query: {
                    status: 'null'
                }
            })
        } else if(status == 'register_succeeded'){
            this.$vs.notification({
                color: 'success',
                position: 'top-right',
                title: this.$t('common.messages.success'),
                text: this.$t('register.messages.registered')
            })
            setTimeout(() => {
                if(this.enabledFeatures['trialDialogs'] === false){
                    return;
                }
                GlobalEventEmitter.$emit('showDialog', TrialDialog, () => {});
            }, 2000);
            // clear it
            this.$router.replace({
                query: {'status': 'null'}
            })

            // language setting detection and apply
            var lang = navigator.language || navigator.userLanguage;
            if (lang != null && lang != '') {
                lang = lang.split('-')[0].toUpperCase();
            }

            const languagesResponse = await apiCall('GET', '/users/languages');
            if (languagesResponse.status != 200) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('profile.messages.cannotSave'),
                    color: 'danger',
                    position: 'top-right'
                });
            } else {
                var lang_id = null;

                var languageMatch = languagesResponse.data.language.find(x => x.name == lang);
                if (languageMatch != null) {
                    lang_id = languageMatch.id;
                } else {
                    lang_id = languagesResponse.data.language.find(x => x.name == 'EN');
                }

                let response = await apiCall('PATCH', '/users/info', {
                    language_id: lang_id
                });
                if (response.status != 200 && response.status != 0) {
                    this.$vs.notification({
                        title: this.$t('common.messages.somethingWentWrong'),
                        text: this.$t('profile.messages.cannotSave'),
                        color: 'danger',
                        position: 'top-right'
                    });
                }
                GlobalEventEmitter.$emit('loadUserMeta')
            }

            
        }

        // if route params check_subscription is true
        if(this.$route.query.check_subscription == 'true'){
            GlobalEventEmitter.$emit('checkSubscriptionActivation', {
                payment_intent_client_secret: this.$route.query.payment_intent_client_secret,
                showPendingScreenOnFail: true
            });

            // clear query param
            this.$router.replace({
                query: {
                    check_subscription: 'null',
                    payment_intent_client_secret: 'null'
                }
            })
        } else {
            GlobalEventEmitter.$emit('showExpiringNotification');
        }

        
    },

    methods: {

        async checkOffline() {
            if (!navigator.onLine) {
                GlobalEventEmitter.$emit('showDialog', OfflineDialog, (e) => {
                    if (e) {
                        window.location.reload();
                    }
                }, null, true)
            } else {
                this.hideDialog();
            }
        },

        async checkUpdate() {
            if (this.$IS_APP) {
                const response = await apiCall('GET', '/version');
                if (response.status == 200) {
                    if (response.data && response.data.minimum_app_version) {
                        // compare it to window.configuration.VERSION
                        const newVersionAvailable = compare(response.data.minimum_app_version, window.configuration.VERSION, '>');
                        if(newVersionAvailable){
                            const info = await Device.getInfo();
                            const platform = info.platform;
                            GlobalEventEmitter.$emit('showDialog', UpdateAppDialog, (e) => {
                                
                                if(e){
                                    if(platform == 'ios'){
                                        window.location.href = 'https://apps.apple.com/it/app/italian-explorer/id';
                                    } else {
                                        window.location.href = 'https://play.google.com/store/apps/details?id=';
                                    }
                                }
                            }, null, true)
                        }
                    }
                }
            }
        },


        centerMap() {
            if (this.mapCentered) {
                return;
            }

            // increment mapCoords latitude of a little bit to trigger the geolocation request
            if (this.user != null && this.user != false) {
                this.mapCoords = [this.mapCoords[0] + 0.00001, this.mapCoords[1]];
            }

            this.askForGeoLocation();

            this.mapCentered = true;
        },

        applyFilters(filters, nearestPoi) {
            this.nearestPoi = nearestPoi;
            Object.assign(this.filters, filters)
            this.filters.__ob__.dep.notify();
        },

        goToCoords(e) {
            this.mapCoords = e;
        },

        askForGPS() {
            this.askForGeoLocation(false);
        },

        async handleNativeGeolocation(){
            try {
                // Request permissions
                const permissions = await Geolocation.requestPermissions();
                if (permissions.location === 'granted') {
                    // Get current position with high accuracy
                    const position = await Geolocation.getCurrentPosition({
                        enableHighAccuracy: true,
                        maximumAge: 30000, // cached from max 30s
                    });

                    if(this.geolocationWatch == null){
                        // also subscribe to position changes
                        this.geolocationWatch = await Geolocation.watchPosition({}, (position, err) => {
                            if (err) {
                                console.error(err);
                            } else {
                                this.geoLocationSuccessCallback(position, this.shouldBeCenteredOnUpdate);
                            }
                        });
                    }

                    this.geoLocationSuccessCallback(position);
                } else {
                    this.geoLocationErrorCallback(true);
                }
            } catch (error) {
                this.geoLocationErrorCallback(true);
            }
        },

        askForGeoLocation(silent = true) {
            if(this.$IS_APP){
                this.handleNativeGeolocation();
                return;
            }

            if ("permissions" in navigator) {
                navigator.permissions.query({
                    name: "geolocation"
                }).then((result) => {
                    if (result.state === "denied") {
                        console.log("denial");
                    } else { // granted or prompt
                        navigator.geolocation.getCurrentPosition(this.geoLocationSuccessCallback, (e) => {
                            console.log(e)
                            this.geoLocationErrorCallback(true)
                        }, {
                            timeout: 4000,
                            maximumAge: 0
                        });
                    }
                });
            } else {
                navigator.geolocation.getCurrentPosition(this.geoLocationSuccessCallback, () => {
                    this.geoLocationErrorCallback(silent)
                });
            }
        

        },

        geoLocationSuccessCallback(e, alsoMoveMap = true) {
            this.userCoords = [e.coords.latitude, e.coords.longitude]
            if(alsoMoveMap){
                this.mapCoords = this.userCoords;
            }
        },

        geoLocationErrorCallback(silent = true) {
            if (silent) {
                return;
            }
            // show an error message to the user
            this.$vs.notification({
                title: this.$t('common.messages.somethingWentWrong'),
                text: this.$t('explore.messages.geoLocationError'),
                color: 'danger',
                position: 'top-right'
            });
        },

        async searchPois(p) {
            // prevent this if is not on Explore page and it's mobile
            if (this.$parent.isMobile && this.$route.name != 'Esplora') {
                return;
            }

            if (this.tripPoisMode) {
                return;
            }
            if (this.user == null || this.user == false) {
                return;
            }

            if(Object.keys(p).length == 0){
                return;
            }
            // use apiCall to make a request to /pois
            const response = await apiCall('GET', '/pois', p);
            // if status code is 200, show a success message 
            if (response.status == 200) {
                this.pois = response.data.data || [];
                if (this.nearestPoi != null && ((this.pois == null || this.pois.length == 0) || this.nearestPoi.forced)) { 
                    // se ha applicato dei filtri di ricerca e non ha risultati nella porzione di mappa visualizzata e ha un "nearestpoi" ce lo porto
                    // anche se nearestPoi è "forced" lo porto forzatamente, significa che ha cercato nella barra per nome di città o regione e ha cliccatoosu un suggerimento
                    GlobalEventEmitter.$emit('setZoom', this.nearestPoi.preferredZoom != undefined ? this.nearestPoi.preferredZoom : 12)
                    GlobalEventEmitter.$emit('goToCoords', [this.nearestPoi.latitude, this.nearestPoi.longitude])
                }
                this.nearestPoi = null;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('explore.messages.unableToLoadPOIs'),
                    color: 'danger',
                    position: 'top-right'
                });
            }

        },

        async boundsChanged(e) {
            this.bounds = {
                north_east_latitude: e._northEast.lat,
                north_east_longitude: e._northEast.lng,
                south_west_latitude: e._southWest.lat,
                south_west_longitude: e._southWest.lng,
            }


            // Calculate the current span
            const lat_span = (this.bounds.north_east_latitude - this.bounds.south_west_latitude);
            const lng_span = (this.bounds.north_east_longitude - this.bounds.south_west_longitude);

            // Make the bounding box 4x times bigger
            let biggerBounds = {
                north_east_latitude: this.bounds.north_east_latitude + lat_span,
                north_east_longitude: this.bounds.north_east_longitude + lng_span,
                south_west_latitude: this.bounds.south_west_latitude - lat_span,
                south_west_longitude: this.bounds.south_west_longitude - lng_span
            };
            this.filters = {
                ...this.filters,
                ...biggerBounds
            }
        },

        closeAddToTripMode() {
            this.addToTripMode = false
            this.$router.go(-1)
        },

        handlePoiClick(e) {
            if (this.addToTripMode) {
                this.selectedPoi = e;
                GlobalEventEmitter.$emit('showDialog', TripDateDialog, this.addToTripDateChosenCallback) // chiedo la data in cui intende visitare il poi
                this.addToTripMode = false;
            } else {
                this.$router.push('/explore/poi/' + e.id);
            }
        },

        async addToTripDateChosenCallback(e) {
            if (e != null && e.length > 0) { // ha scelto un nome per il viaggio e ha premuto "prosegui" (non annulla)
                // to do, at the moment doesn't do anything
                if (this.trip.pois == null) {
                    this.trip.pois = []
                }
                this.trip.pois.push({
                    id: this.selectedPoi.id,
                    is_custom_poi: false,
                    start_date: e + 'T00:00:00Z',
                    end_date: e + 'T00:00:00Z',
                    position: this.trip.pois.length // to be sure it's the last one
                })
                const loading = this.$vs.loading();
                var r = await this.saveTrip();
                loading.close();
                if (r) {
                    this.$router.push({
                        path: '/explore/trips/' + this.trip.id
                    });
                }
                this.addToTripMode = false;
            } else {
                this.addToTripMode = true;
            }

        },

        async saveTrip() {

            const response = await apiCall('PUT', '/journeys/' + this.trip.id, this.trip);
            // if status code is 200, show a success message 
            if (response.status == 200) {
                // show sucecss message
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: 'Il viaggio è stato aggiornato con successo',
                    color: 'success',
                    position: 'top-right'
                });
                return true;
            } else if (response.status == 409) {
                // show sucecss message
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('trips.messages.poiAlreadyInTrip'),
                    color: 'danger',
                    position: 'top-right'
                });
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('explore.messages.unableToAddPOIToTrip'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
            return false;
        },

        showDialog(component, callback, data = null, preventClose = false) {
            this.dialogComponent = component;
            this.dialogCallback = callback;
            this.dialogData = data;
            this.dialogPreventClose = preventClose;
        },
        hideDialog() {
            this.dialogComponent = null;
            this.dialogCallback = null;
            this.dialogData = null;
        },

        showRoute(n) {
            // do nothing from mobile
            if (this.$parent.isMobile) {
                return;
            }

            if (this.panel != null) {
                this.panel.hide();
                this.panel = null;
            }
            if (!n.meta.side) {

                return;
            }

            this.panel = this.$showPanel({
                component: BreadCumbWrapper,
                openOn: 'right',
                props: {
                    component: this.pages[n.name],
                    name: this.$parent.backName,
                    back: this.$parent.back,
                    user: this.user,
                    isMobile: this.$parent.isMobile,
                    params: n.params != null ? {
                        ...n.params,
                        user: this.user
                    } : {}
                }
            });

            this.panel.promise
                .then(result => {
                    if (this.panel != null && this.panel.id == result.id) { // c'è un panel aperto, ed è lo stesso che si sta chiudendo
                        this.$router.push('/explore/')
                        this.panel = null;
                    }
                });
        },


        async showPaymentRequired(){
            if(this.enabledFeatures['trialDialogs'] === false){
                return;
            }

            let c = TrialExpiredDialog;

            // find (if exists) the transaction with status EXPIRED_AFTER_DEACTIVATION and EXPIRED with the most recent date (transaction are already sorted by date, using find we will get the first one)
            const lastTransaction = this.user.transactions.find(x => x.status == 'EXPIRED_AFTER_DEACTIVATION' || x.status == 'EXPIRED');

            if(lastTransaction){
                // determinate which dialog to show
                if(lastTransaction.status == 'EXPIRED_AFTER_DEACTIVATION'){
                    c = DeactivatedDialog;
                } else if(lastTransaction.status == 'EXPIRED'){
                    c = RenewFailedDialog;
                }
            }

            GlobalEventEmitter.$emit('showDialog', c, () => {
                this.$router.push({
                    name: 'Sottoscrizione'
                })
            })
        },
    },

    watch: {
        '$route'(n) {
            console.log(n.path)
            if(this.$parent.isMobile && n.name != 'Esplora' && !n.path.startsWith('/explore/poi') ){
                // leaving route explore and not in POI Details, reset all filters but not the map bounds

                this.filters = {...this.bounds};
            }
            
            this.showRoute(n);
            
            if (n.meta.map) {
                this.centerMap();
            }
            this.tripPoisMode = false;

            /*if (n.name == 'Esplora') {
                GlobalEventEmitter.$emit('showExpiringNotification');
            }*/
        },
        user(n, o) {
            if (o == null && n != null) {
                if (this.$route.meta.map) {
                    this.centerMap();
                }
            }
        },

        filters(n) {
            this.searchPois(n);
        },

        tripPoisMode(n, o) {
            if (n == false && o == true) {
                this.searchPois(this.filters);
            }
        },

        tripPois(n) {
            if (n != null && n.length > 0) {
                this.tripPoisMode = true;
                this.pois = n;
                GlobalEventEmitter.$emit('setZoom', 15)
                GlobalEventEmitter.$emit('goToCoords', [this.pois[0].latitude, this.pois[0].longitude])
            }
        }
    }
}
</script>

<style scoped>
.explore-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    box-sizing: border-box
}

.route-map {
    padding: 0 !important;
}

/* MOBILE */
@media (max-width: 600px) {
    .explore-container {}
}

body.app .explore-container {

    padding-top: max(10px, calc(10px + env(safe-area-inset-top))) !important;
    padding-bottom: 15px!important;
}
</style>
